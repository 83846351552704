<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/app_logo.jpeg"
                      alt=""
                      height="40"
                    />
                  </span>
                </router-link>

              </div>
              <p class="text-muted mb-4 mt-3">
                Enter your email address and password to access admin panel.
              </p>
            </div>

            <form action="#" @submit.prevent="tryToLogIn">
              <b-alert
                :variant="notification.type"
                class="mt-3"
                v-if="notification.message"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ notification.message }}</b-alert
              >

              <div class="form-group mb-3">
                <label for="emailaddress">Email address</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  id="emailaddress"
                  placeholder="Enter your email"
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div
                  v-if="submitted && $v.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.email.required">Email is required.</span>
                  <span v-if="!$v.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">Password</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="password"
                    :type="obscurePassword ? 'password': 'text'"
                    id="password"
                    class="form-control"
                    placeholder="Enter your password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text" @click="obscurePassword = !obscurePassword">
                      <span class="password-eye"></span>
                    </div>
                  </div>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Password is required.
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="checkbox-signin"
                    checked
                  />
                  <label class="custom-control-label" for="checkbox-signin"
                    >Remember me</label
                  >
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-dark btn-block" type="submit">
                  Log In
                </button>
              </div>
            </form>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <router-link to="/forgot-password" class="text-muted ml-1"
                >Forgot your password?</router-link
              >
            </p>

          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";

import { required, email } from "vuelidate/lib/validators";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      tryingToLogIn: false,
      obscurePassword: true
    };
  },
  components: {
    Auth,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.getters.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.getters.notification ? 5 : 0;
    },
  },
  created() {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.email && this.password) {
          this.$store.dispatch("login", {
            email: this.email,
            password: this.password,
          });
          this.$store.dispatch("clear");
        }
      }
    },
  },
};
</script>
